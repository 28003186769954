import { Controller } from "@hotwired/stimulus"
import CableReady from "cable_ready"
import * as bootstrap from "bootstrap"

CableReady.operations.closeModal = operation => {
  setTimeout(() => {
    const selector = operation.selector || ".modal.show"
    const modal = document.querySelector(selector) || document.querySelector(".modal.show")
    const modalInstance = bootstrap.Modal.getInstance(modal)
    modalInstance?.hide()
  }, 300)
}

CableReady.operations.openModal = operation => {
  setTimeout(() => {
    const modal = document.querySelector(operation.selector)
    const modalInstance = bootstrap.Modal.getInstance(modal)
    modalInstance?.show()
  }, 300)
}

export class BaseModal extends Controller {
  connect() {
    new bootstrap.Modal(this.element)

    this.element.addEventListener("show.bs.modal", this.showModal.bind(this))
    this.element.addEventListener("hidden.bs.modal", this.hideModal.bind(this))

    this.afterModalSetup?.()
  }

  disconnect() {
    this._cleanUpModal()
  }

  showModal(event) {
    this._setModalTitle(event)
  }

  hideModal(event) {
    this._resetForms()
    this._resetMultiSelects()
    this._resetFlatpickrs()
  }

  teardown() {
    this.hideModal()
  }

  // private
  _resetForms() {
    // use default form resetting behavior
    this.element.querySelectorAll("form").forEach(f => f.reset())
  }

  _resetFlatpickrs() {
    // clear any flatpickr instance
    this.element.querySelectorAll("input").forEach(e => e?._flatpickr?.clear())
  }

  _resetMultiSelects() {
    this.element.querySelectorAll("select").forEach(e => e?.slimSelect?.setSelected([]))
  }

  _cleanUpModal() {
    this.bsModal?.dispose()
  }

  _setModalTitle(event) {
    if (!event.relatedTarget) { return }

    const { modalTitle } = event.relatedTarget.dataset
    const titleElement = this.element.querySelector(".modal-header .modal-title")

    if (modalTitle && titleElement) {
      titleElement.innerText = modalTitle
    }
  }

  get bsModal() {
    return bootstrap.Modal.getInstance(this.element)
  }
}