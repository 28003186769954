import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

const setInnerHtml = (event) => {
  const { target } = event
  const { dataset } = target
  const { content } = dataset
  const popoverId = target.getAttribute("aria-describedby")

  document.querySelector(`.popover#${popoverId} .popover-body`).innerHTML = content
}

export default class extends Controller {
  connect() {
    if (this.isPopoverElement) this.setupPopover()
  }

  get isPopoverElement() {
    const { dataset } = this.element
    const keys = Object.keys(dataset)

    return ((keys.includes("content") || keys.includes("title")) && keys.includes("trigger"))
  }

  setupPopover() {
    new bootstrap
      .Popover(this.element, {
        animation: false,
        content: "-"
      })
    this.element.addEventListener("shown.bs.popover", setInnerHtml)
  }

  close() {
    if (!document.querySelector(".popover.show")) { return }

    document
      .querySelectorAll("[data-controller='popover']")
      .forEach(e => bootstrap.Popover.getInstance(e)?.hide())
  }

  disconnect() {
    this.close()
  }
}